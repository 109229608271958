'use strict'

angular
.module('cb.controllers', [])
.controller('ActivityModalLinkController', ["$scope", "$modal", function ($scope, $modal) {
    $scope.openActivityLogModal = function(id) {
        var modalInstance = $modal.open({
            animation: true,
            size: 'lg',
            templateUrl: "views/modal-templates/activity-log.html",
            controller: "ActivityLogController",
            resolve: {
                Id: function () {
                    return id;
                }
            }
        });
    };
}])
.controller('CBPaginationController', ["$scope", function ($scope) {
    $scope.decrement = function () {
        $scope.pageNumber--;
    };

    $scope.increment = function () {
        $scope.pageNumber++;
    };
}])